<template>
  <div>
    <form-create :is-add-new-sidebar-active.sync="isAddNewSidebarActive" :marca-options="marcaOptions"
      :unidade-medida-options="unidadeMedidaOptions" :familia-produto-options="familiaOptions" titulo="Novo Produto" @refetch-data="refetchData" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Produtos
      </h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Exibição</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Pesquisar..." />
              <b-button variant="success" @click="isAddNewSidebarActive = true">
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table ref="refItemListTable" class="position-relative" :items="fetchDados" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc" :busy="isLoad" striped>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <!-- Column: Razao Social -->
        <template #cell(razao_social)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="4.5em" :src="data.item.imagem" :text="avatarText(data.item.razao_social)"
                :variant="'secondary'" class="avatar-with-shadow" />
            </template>
            <div><small>
                <TooltipTextoCortado :texto="data.item.razao_social" :limite="25" />
              </small></div>
            <small>{{ data.item.contato_responsavel }}</small>
          </b-media>
        </template>
        <template #cell(cnpj)="data">
          <small>{{ data.item.cnpj ? data.item.cnpj : data.item.cpf }}</small>
        </template>
        <!-- Column: Endereço -->
        <template #cell(unidade_medida)="data">
          <small> {{ data.item.unidade_medida.abreviacao }}</small>
        </template>
        <template #cell(descricao)="data">
          <div class="font-small-2">
            <TooltipTextoCortado :texto="data.item.descricao" :limite="45" />
          </div>
        </template>
        <template #cell(nome)="data">
          <div class="font-small-2" v-if="data.item.nome">
            <TooltipTextoCortado :texto="data.item.nome" :limite="25" />
          </div>
        </template>
        <template #cell(id)="data">
          <small>{{ data.item.codigo }}</small>
        </template>
        <template #cell(marca)="data">
          <div class="d-flex">
            <div>
              <b-avatar v-if="data.item.marca" size="3.5em" :src="data.item.marca.logo"
                :text="avatarText(data.item.marca.nome)" :variant="'secondary'" class="avatar-with-shadow" />
              <div class="text-center" v-else> - </div>
            </div>
            <div class="font-small-2 ml-1">
              <div v-if="data.item.nome"><strong>Prod: </strong>{{ data.item.nome }}</div>
              <div v-if="data.item.marca"><strong>Mar:</strong> {{ data.item.marca.nome }}</div>

            </div>
          </div>

        </template>
        <template #cell(fotos)="data">
          <div v-if="data.item.fotos.length > 0" class="d-flex position-relative hover-container"
            v-b-tooltip.hover.top="`Clique para ver fotos do produto`">
            <img :src="data.item.fotos[0].path" width="50" height="50" class="mr-1 hover-image"
              @click="showCarousel(data.item.fotos)" />
            <div class="hover-overlay" @click="showCarousel(data.item.fotos)">
              <span class="photo-count">{{ data.item.fotos.length }}</span>
            </div>
          </div>
          <div class="text-center" v-else> - </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown v-if="$can('atualizar', 'produtos')" variant="link" no-caret
            :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'produtos-editar', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalItens" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <modalConfirmacao id="modal-confirmacao" titulo="Excluir Item" :item-id="dadosItem.id"
        :item-nome="dadosItem.razao_social" @itemConfirmado="excluirItem" @click="modalExcluir(data.item)" />
    </b-card>

    <!-- Modal for Carousel -->
    <b-modal id="carousel-modal" ref="carouselModal" title="Fotos do Produto" hide-footer>
      <b-carousel id="carousel-fotos" v-model="carouselIndex" :interval="0" controls indicators background="#ababab">
        <b-carousel-slide v-for="(foto, index) in carouselFotos" :key="index" :img-src="foto.path"></b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
  import {
    BAvatar,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BMedia,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BModal,
    BCarousel,
    BCarouselSlide,
    VBTooltip
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'

  import { avatarText } from '@core/utils/filter'
  import { onUnmounted, ref } from '@vue/composition-api'
  import itemStoreModule from '../itemStoreModule'
  import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
  import store from '@/store'
  import TooltipTextoCortado from '@/views/components/componentesPensou/TooltipTextoCortado.vue'
  import FormCreate from './FormCreate.vue'
  import useItemList from './useItemList'

  export default {
    components: {
      FormCreate,
      modalConfirmacao,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BDropdown,
      BDropdownItem,
      BPagination,
      BSpinner,
      vSelect,
      TooltipTextoCortado,
      BModal,
      BCarousel,
      BCarouselSlide,
      VBTooltip,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    setup() {
      const ITEM_APP_STORE_MODULE_NAME = 'produtos'

      // Register module
      if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, itemStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
      })

      const isAddNewSidebarActive = ref(false)

      const unidadeMedidaOptions = ref([])
      store.dispatch('produtos/fetchUnidadeMedida')
        .then(response => {
          unidadeMedidaOptions.value = response.data
        })

      const marcaOptions = ref([])
      store.dispatch('produtos/fetchMarca')
        .then(response => {
          marcaOptions.value = response.data
        })

      const familiaOptions = ref([])
      store.dispatch('produtos/fetchFamiliaProduto')
        .then(response => {
          familiaOptions.value = response.data
        })

      const {
        fetchDados,
        tableColumns,
        perPage,
        currentPage,
        totalItens,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refItemListTable,
        refetchData,
        deleteItem,

      } = useItemList()

      const carouselFotos = ref([])
      const carouselIndex = ref(0)
      const carouselModal = ref(null)

      const showCarousel = fotos => {
        carouselFotos.value = fotos
        carouselIndex.value = 0
        carouselModal.value.show()
      }

      return {
        isAddNewSidebarActive,
        deleteItem,
        fetchDados,
        tableColumns,
        perPage,
        currentPage,
        totalItens,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refItemListTable,
        refetchData,
        avatarText,
        unidadeMedidaOptions,
        marcaOptions,
        carouselFotos,
        carouselIndex,
        showCarousel,
        carouselModal,
        familiaOptions,
      }
    },
    data() {
      return {
        dadosItem: {
          id: '',

        },
      }
    },
    computed: {
      isLoad() {
        return this.$store.state['produtos'].load
      },
    },

    methods: {
      modalExcluir(item) {
        this.dadosItem.razao_social = item.descricao
        this.dadosItem.id = item.id
        this.$root.$emit('bv::show::modal', 'modal-confirmacao')
      },
      excluirItem(dados) {
        this.deleteItem(dados)
      },
    },

  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .hover-container {
    position: relative;
    cursor: pointer;
  }

  .hover-image {
    display: block;
  }

  .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
  }

  .hover-container:hover .hover-overlay {
    opacity: 1;
  }

  .photo-count {
    color: white;
    font-size: 14px;
    margin-left: 8px;
  }
</style>